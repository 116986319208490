<template>
  <div>
    <div v-if="isLoaded()" class="duck-form">
      <NotiBar v-if="msgShow" :type="msgType" :msg="msgText" />

      <NotiBar v-if="member.type==20 && member.type_request==9" type="warning" msg="ผู้ใช้งานรายนี้มีการแจ้งขอเปลี่ยนเป็นช่าง/ผู้รับเหมา" />

      <form @submit.prevent="saveForm">
        <div v-if="haveEditRole()" class="button-block">
          <button type="submit" class="btn btn-update">
            <i class="fa fa-save fa-lg"></i> บันทีก
          </button>
          <button @click="cancelRequestConstructor()" type="button" class="btn btn-cancel" v-if="member.type==20 && member.type_request==9">
            <i class="fa fa-close fa-lg"></i> ปฏิเสธการขอสถานะ
          </button>

          <button v-if="isConstructor()" type="button" @click="clickDownloadZip()" class="btn btn-download">
              <i class="fa fa-download"></i> ดาวน์โหลดไฟล์
            </button>
        </div>

        <div class="row2 row2-form">
          <div class="col">
            <fieldset class="form-block">
              <div class="h-title">
                <h3>General Information</h3>
              </div>
              <div class="form-container">
                <SmallTitle title="ข้อมูลชื่อผู้ใช้งาน" />
                <div class="input row2">
                  <div class="col">
                    <Label text="ประเภทของสมาชิก" />
                    <select name="type" v-model="member.type">
                      <option value="10">ผู้ใช้งาน + ช่าง/ผู้รับเหมา</option>
                      <option value="20">ผู้ใช้งาน</option>
                    </select>
                  </div>
                  <div class="col">
                    <Label text="สถานะของสมาชิก" />
                    <select name="status" v-model="member.status">
                      <option value="10">Disable</option>
                      <option value="90">Enable</option>
                    </select>
                  </div>
                </div>

                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.first_name.$error }">
                    <label>
                      ชื่อ <span class="required">*</span>
                    </label>
                    <input type="text" v-model.trim="$v.member.first_name.$model">
                  </div>
                  <div class="col" :class="{ error: $v.member.last_name.$error }">
                    <label>
                      นามสกุล <span class="required">*</span>
                    </label>
                    <input type="text" v-model.trim="$v.member.last_name.$model">
                  </div>
                </div>

                <div class="input">
                  <label>ประเภทขององค์กร <span class="required">*</span></label>
                  <select v-model="member.business_type">
                    <option value="1">บุคคลธรรมดา</option>
                    <option value="9">นิติบุคคล</option>
                  </select>
                </div>
                <div v-if="member.business_type==9">
                  <div class="input row2">
                    <div class="col">
                      <label>ชื่อบริษัท/สถานประกอบการ <span class="required">*</span></label>
                      <input type="text" v-model="member.company_name">
                    </div>
                    <div class="col" :class="{ error: $v.member.refer_id.$error }">
                      <label>เลขประจำตัวผู้เสียภาษี <span class="required">*</span></label>
                      <input type="text" v-model.trim="$v.member.refer_id.$model" maxlength="13">
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="input row2">
                    <div class="col" :class="{ error: $v.member.refer_id.$error }">
                      <label>รหัสบัตรประชาชน <span class="required">*</span></label>
                      <input type="text" v-model.trim="$v.member.refer_id.$model" maxlength="13">
                    </div>
                    <div class="col">
                      <label>ต้องการใบกำกับภาษี <span class="required">*</span></label>
                      <select v-model="member.tax_invoice">
                        <option value="0">ไม่ต้องการ</option>
                        <option value="1">ต้องการใบกำกับภาษี</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="input row2">
                  <div class="col">
                    <label>เริ่มดำเนินธุรกิจตั้งแต่ปี</label>
                    <input type="text" v-model="member.start_business_year">
                  </div>
                  <div class="col">
                    <label>รวมทั้งหมด (ปี)</label>
                    <input type="text" v-model="member.start_business_total">
                  </div>
                </div>

                <div v-if="isConstructor()" class="input">
                  <label>ประเภทงานที่รับ (เลือกได้มากกว่า 1 ข้อ)</label>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="1"> ออกแบบตกแต่งภายใน
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="2"> ออกแบบสถาปัตยกรรม
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="3"> ออกแบบโครงสร้าง
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="4"> ขออนุญาตก่อสร้าง
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="5"> งานก่อสร้างทั่วไป
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="6"> งานตกแต่งภายใน
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="7"> งานซ่อมแซมทั่วไป
                  </div>
                  <div>
                    <input type="checkbox" v-model="member.job_type" value="8"> รับเหมาเฉพาะค่าแรงก่อสร้าง
                  </div>
                  <div>
                    <!-- <input type="checkbox" v-model="member.job_type" value="999">  -->
                    อื่นๆ (โปรดระบุ)
                    <input type="text" v-model="member.job_type_opt">
                  </div>
                </div>

                <div class="input" :class="{ error: $v.member.email.$error }">
                  <!-- , error: err.email_repeat -->
                  <label>
                    อีเมล์ <span class="required">*</span>
                  </label>
                  <input type="email" v-model.trim="$v.member.email.$model">
                </div>
                <div class="input row2">
                  <div class="col">
                    <label>
                      รหัสผ่าน (ขั้นต่ำ 6 ตัวอักษร)
                    </label>
                    <input type="password" v-model.trim="member.pass">
                  </div>
                  <div class="col">
                    <label>
                      รหัสผ่านยืนยัน
                    </label>
                    <input type="password" v-model.trim="member.repass">
                  </div>
                </div>
                
                <SmallTitle title="ข้อมูลการติดต่อ" class="mt-20" />
                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.phone.$error }">
                    <!-- , error: err.phone_repeat -->
                    <label>
                      หมายเลขโทรศัพท์มือถือ <span class="required">*</span>
                    </label>
                    <input type="text" v-model.trim="$v.member.phone.$model" maxlength="10">
                  </div>
                  <div class="col">
                    <label>
                      LINE user id
                    </label>
                    <input type="text" v-model="member.line_userid" disabled>
                  </div>
                  <div class="input-description">กรุณากรอกหมายเลขโทรศัพท์ที่เชื่อมกับ LINE ของท่าน เพื่อให้ท่านสามารถได้รับการส่งข้อความแจ้งเตือนความเคลื่อนไหวจากทางเราได้</div>
                </div>

                <div class="input" :class="{ error: $v.member.address.$error }">
                    <label>ที่อยู่ <span class="required">*</span></label>
                    <input type="text" v-model.trim="$v.member.address.$model">
                </div>
                
                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.subdistrict.$error }">
                    <label>ตำบล <span class="required">*</span></label>
                    <input type="text" v-model.trim="$v.member.subdistrict.$model">
                  </div>

                  <div class="col" :class="{ error: $v.member.district.$error }">
                    <label>อำเภอ <span class="required">*</span></label>
                    <input type="text" v-model.trim="$v.member.district.$model">
                  </div>
                </div>

                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.province.$error }">
                    <label>จังหวัด <span class="required">*</span></label>
                    <select v-model="$v.member.province.$model">
                      <option value="">- - เลือกจังหวัด - - </option>
                      <option v-for="p in province_th" :key="p" :value="p">{{ p }}</option>
                    </select>
                  </div>

                  <div class="col" :class="{ error: $v.member.zipcode.$error }">
                    <label>รหัสไปรษณีย์ <span class="required">*</span></label>
                    <input type="text" v-model.trim="$v.member.zipcode.$model" maxlength="5">
                  </div>
                </div>

              </div>
            </fieldset>
            <!-- end: General Informatin -->


            <fieldset v-if="isConstructor()" class="form-block">
              <div class="h-title">
                <h3>Construtor Information</h3>
              </div>
              <div class="form-container">
                <SmallTitle title="ข้อมูลการรับงาน" />
                <div class="input row2">
                  <div 
                    v-if="member.business_type==9" 
                    class="col" 
                    :class="{ error: $v.member.registered_capital.$error }"
                  >
                    <label>
                      ทุนจดทะเบียนบริษัท <span class="required">*</span>
                    </label>
                    <input 
                      type="text" 
                      v-model.trim="$v.member.registered_capital.$model"
                    >
                  </div>
                  <div class="col" :class="{ error: $v.member.annual_sales.$error }">
                    <label>
                      มูลค่ายอดขายต่อปี <span class="required">*</span>
                    </label>
                    <input type="text" v-model.trim="$v.member.annual_sales.$model">
                  </div>
                </div>
                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.maximum_value.$error }">
                    <label>
                      มูลค่าต่องานสูงสุดที่รับ <span class="required">*</span>
                    </label>
                    <input type="text" v-model.trim="$v.member.maximum_value.$model">
                  </div>
                  <div class="col" :class="{ error: $v.member.minimum_value.$error }">
                    <label>
                      มูลค่าต่องานต่ำสุดที่รับ <span class="required">*</span>
                    </label>
                    <input type="text" v-model.trim="$v.member.minimum_value.$model">
                  </div>
                </div>

                <div class="input">
                  <label>พื้นที่สะดวกในการรับงาน</label>
                  <span class="input-checkbox">
                    <input type="checkbox" v-model="member.work_area_bangkok" value="1"> กรุงเทพฯและปริมณฑล
                  </span>
                  <span class="input-checkbox">
                    <input type="checkbox" v-model="member.work_area_provincial" value="1"> ต่างจังหวัด
                  </span>
                </div> 
                <div class="input">
                  <label>รายละเอียดพื้นที่รับงานเพิ่มเติม (ถ้ามี)</label>
                  <input type="text" v-model="member.work_area_detail">
                </div>
                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.total_tecnician.$error }">
                    <label>จำนวนช่าง (คน)</label>
                    <input type="number" v-model.trim="$v.member.total_tecnician.$model">
                  </div>

                  <div class="col" :class="{ error: $v.member.total_engineer.$error }">
                    <label>มีวิศวกร (คน)</label>
                    <input type="number" v-model.trim="$v.member.total_engineer.$model">
                  </div>
                </div>
                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.total_architecture.$error }">
                    <label>จำนวนสถาปนิก (คน)</label>
                    <input type="number" v-model.trim="$v.member.total_architecture.$model">
                  </div>

                  <div class="col" :class="{ error: $v.member.total_officer.$error }">
                    <label>จำนวนพนักงานธุรการ (คน)</label>
                    <input type="number" v-model.trim="$v.member.total_officer.$model">
                  </div>
                </div>
                <div class="input row2">
                  <div class="col" :class="{ error: $v.member.total_foreign_worker.$error }">
                    <label>จำนวนพนักงานต่างด้าว (คน)</label>
                    <input type="number" v-model.trim="$v.member.total_foreign_worker.$model">
                  </div>
                  <div class="col">
                    <label>มีโรงงานผลิตเอง</label>
                    <select v-model="member.has_factory">
                      <option value="0">ไม่มี</option>
                      <option value="10">มีโรงงานผลิตเอง</option>
                    </select>
                  </div>
                </div>
                <div class="input">
                    <label>โรงงานผลิตขนาด</label>
                    <textarea v-model.trim="member.factory_size"></textarea>
                </div>
                <div class="input">
                    <label>หมายเหตุอื่นๆ</label>
                    <textarea v-model.trim="member.own_equipment"></textarea>
                </div>

                <SmallTitle title="ข้อมูลการรับงาน" class="mt-20" />
                <div class="input">
                  <label>ตัวอย่างผลงานที่มีชื่อเสียง</label>
                  <textarea v-model.trim="member.experience"></textarea>
                </div>
              </div>
            </fieldset>
          </div>


          <div v-if="isConstructor()" class="col col-file">
            <fieldset class="form-block">
              <div class="h-title">
                <h3>File</h3>
              </div>
              <div class="form-container">
                <div v-if="member.business_type=='1'">
                  <SmallTitle title="สำเนาบัตรประชาชน" />
                  <SingleFileUploader type='citizen_card' :parentId="memberId" section="member" />
                  
                  <SmallTitle title="ทะเบียนบ้าน" class="mt-20" />
                  <SingleFileUploader type='house_registration' :parentId="memberId" section="member" />
                </div>
                <div v-else>
                  <SmallTitle title="ใบ ภพ 20" />
                  <SingleFileUploader type='company_registration' :parentId="memberId" section="member" />
                  
                  <SmallTitle title="หนังสือรับรอง" />
                  <SingleFileUploader type='company_certificate' :parentId="memberId" section="member" />
                </div>
                
                <SmallTitle title="ใบ Certificate (ถ้ามี)" class="mt-20" />
                <MultipleFileUploader type='certificate' :parentId="memberId" section="member" />
                
                <SmallTitle title="Portfolio/ตัวอย่างงาน (ถ้ามี)" class="mt-20" />
                <MultipleFileUploader type='portfolio' :parentId="memberId" section="member" />

                <FileUploaderRemark />
              </div>
            </fieldset>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import {apiRequest}   from '@/utils/axios/axiosInstance.js';
import {numberInsertComma} from '@/utils/number/numberInsertComma.js';
import {province_th}  from '@/variables/province.js'
const { required, numeric, email, minLength} = require('vuelidate/lib/validators') //sameAs

import Label                from '@/components/common/form/Label.vue';
import NotiBar              from '@/components/common/NotiBar.vue';
import SmallTitle           from '@/components/common/SmallTitle.vue';
import SingleFileUploader   from '@/components/file/SingleFileUploader.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';
import FileUploaderRemark   from '@/components/file/FileUploaderRemark.vue';

import '@/assets/css/extends/form.css';

export default {
  // name: 'Member Form',
  data() {
    return {
      is_add: true,
      pageTitle: '',
      memberId: '',
      msgShow: false,  
      msgType:'success',
      msgText:'',
      member: {},
      memberStatus: 0,
      province_th: [],
    }
  },
  validations: {
    // repassword: {
    //   sameAs: sameAs(function() {
    //     return this.member.pass;
    //   })
    // }, //
    member: {
      // pass: {required},
      first_name: {required},
      last_name: {required},
      refer_id: {required, numeric,minLength: minLength(8)}, //
      email: {required, email}, //

      phone: {required}, //numeric
      address: {required},
      subdistrict: {required},
      district: {required},
      province: {required},
      zipcode: {required, numeric, minLength: minLength(5)},

      registered_capital: {required},
      annual_sales: {required},
      maximum_value: {required}, //
      minimum_value: {required},
      total_tecnician: {required, numeric},
      total_officer: {required, numeric},
      total_engineer: {required, numeric},
      total_architecture: {required, numeric},
      total_foreign_worker: {required, numeric},
    }
  },
  components: {
    Label,
    NotiBar,
    SmallTitle,
    MultipleFileUploader,
    SingleFileUploader,
    FileUploaderRemark,
  },
  created() {
    this.$store.commit('setLoaderShow', true);
    this.province_th = province_th

    this.setForm();
  },
  watch:{
    $route() {
      this.setForm();
    },
    member: {
      handler: function(newValue) {
        this.member.maximum_value = numberInsertComma(newValue.maximum_value);
        this.member.minimum_value = numberInsertComma(newValue.minimum_value);
      },
      deep: true
    }
  },
  methods: {
    /**
     * have edit data permisstion
     */
    haveEditRole() {
      return this.$store.state.user.type>=50;
    },

    /**
     * detect this member is constructor or not
     *  this status may be on request to be constructor too.
     */
    isConstructor() {
      return this.member.type==10 || this.member.type_request==9;
    },

    /**
     * Detect this page and data is loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Load data from API and prepare data to form
     */
    setForm:function() {
      this.memberId = this.$route.params.id;
      
      apiRequest
        .get('/member/'+this.memberId,{})
        .then( (res) => {
          let head_title = 'Add Member';
          this.member = res.data.result;
          this.member.job_type = (this.member.job_type==null) ? [] : this.member.job_type.split(',');

          // set new member is default 'disable' status (not new member)
          if(this.member.status==0) {
            this.is_add = true;

            this.member.status    = 10; 
            this.member.refer_id  = '';
            this.member.email     = '';
            this.member.phone     = '';
            this.member.province  = '';
            this.member.zipcode   = '';
          }else{
            head_title = 'Edit Member';
          }
          
          this.$store.commit('setPageTitle', head_title);
          this.$store.commit('setHeadTitle', head_title);
          this.$store.commit('setLoaderShow', false);
        });
    },

    /**
     * Reset member form
     * - if is_add form redirect to edit form.
     * - if is edit form, hide preload circle and show save data complete message.
     */
    resetForm() {
      if(this.is_add) {
        this.$router.push('/member/edit/' + this.memberId);
      }else{
        this.setForm();
        this.msgShow = true;
        this.msgText = 'Save data complete';
        this.$store.commit('setLoaderShow', false);
      }
    },

    /**
     * Save member form
     */
    saveForm:function(e) {
      // detect current user can edit this Member or not
      if(!this.haveEditRole()) {
        alert('สิทธิ์ของท่านไม่สามารถแก้ไขข้อมูลสมาชิกคนนี้ได้ !!!');
        return;
      }
      
      // detect form validation error
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.err_message = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      } else {
        this.loaderShow = true;
        this.$store.commit('setLoaderShow', true);
        
        if(this.member.type==10 && this.member.type_request==9) {
          this.member.type_request = 10;
        }

        // save data
        apiRequest
          .post('/member/update', this.member)
          .then((res) => {
            switch(res.data.status) {
              case 301:
                alert('อีเมล์นี้มีผู้ใช้งานอื่นใช้งานแล้ว กรุณาลองใหม่');
              break;
              case 302:
                alert('หมายเลขโทรศัพท์นี้มีผู้ใช้งานอื่นใช้งานแล้ว กรุณาลองใหม่');
              break;

              default:
                this.resetForm();
              break;
            }
            
            this.$store.commit('setLoaderShow', false);
          });
      }
      e.preventDefault();
    },


    /**
     * Cancel request for constructor role
     */
    cancelRequestConstructor() {
      let conf = confirm("คุณต้องการยกเลิกการแจ้งขอสถานะ 'ช่าง/ผู้รับเหมา' ของผู้ใช้งานรายนี้ใช่หรือไม่ ?");
      if(conf) {
        this.member.type_request = 5;
        this.saveForm();
      }
    },



    /**
     * Click event handler for download file
     */
    clickDownloadZip() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get(
          '/file/download_zip', 
          { params: {
            section: 'member',
            parent_id: this.memberId
          }}
        )
        .then( (res) => {
          this.$store.commit('setLoaderShow', false);
          console.log(res.data);
          if(res.data.status==200) {
            window.location.href = res.data.uri;
          } else {
            alert('ไม่พบไฟล์ในเนื้อหานี้');
          }
            
        })
    },
  }
}
</script>


<style scoped>
.col-file {
  padding-left: 20px;
}

.btn-download {
  float: right;
  background: #c00;
}
</style>